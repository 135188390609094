
// import Rails from "@rails/ujs"
// import "@hotwired/turbo-rails";
// import * as ActiveStorage from "@rails/activestorage"
// import "channels"
// import * as bootstrap from 'bootstrap'
//= import "bootstrap/dist/js/bootstrap.bundle.js"
// // import "../stylesheets/application.scss"
// Rails.start()
// ActiveStorage.start()
// require("packs/player")
//= require jquery3
//= window.$ = window.jQuery = require("jquery");
//= window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');


import Rails from "@rails/ujs"
import "@hotwired/turbo-rails";
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import 'bootstrap' // You only need to import it once if you're using ES6 import
import "../stylesheets/application.scss"




Rails.start()
ActiveStorage.start()
require("packs/player")

// Only one jQuery import is necessary, the way it's set depends on other uses in your application
import $ from 'jquery'
window.$ = window.jQuery = $;








